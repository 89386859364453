//Assets
import Novayalogo from '../../Assets/logos/novaya.webp';
import LogoTotalSegWhite from '../../Assets/logos/totalseg-white-complet.png';
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";

//Imports
import './Footer.style.css';
import whatsappNumber from '../../Services/WhatsappNumber';
import { useState } from 'react';
import { Link } from 'react-router-dom';

//Components/Sections

//Configs
const linkFacebook = '';
const linkLinkedin = 'https://www.linkedin.com/company/totalseg-distribuidora/about/';
const linkInstagram = 'https://www.instagram.com/totalsegdistribuidora/';
const linkWhatsapp = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;
const linkToFetch = "https://formsubmit.co/totalseg@totalseges.com.br"

const Footer = () => {
  const [emailNewsletter, setEmailNewsletter] = useState('');
  const submitNewsletter = (e) => {
    e.preventDefault();
    let btnNewsletter = document.querySelector('button#btn-form-newsletter')
    if (emailIsValid(emailNewsletter)) {
      btnNewsletter.innerHTML = 'Enviando...'
      fetch(linkToFetch, {
        method: "post",
        headers: { "Accept": "aplication/json", "Content-Type": "application/json" },
        body: JSON.stringify({ 
          _captcha: 'false',
          _subject: 'Novo Contato para NewsLetter no Site!',
          Email: emailNewsletter,
        })
      }).then(() => {
        btnNewsletter.innerHTML = 'Enviado!'
        setTimeout(() => {
          btnNewsletter.innerHTML = 'Enviar';
          setEmailNewsletter('');
        }, 5000);
      })
    } else {
      alert('Email inválido!');
    };
  };

  const emailIsValid = (email) => {
    return email !== '';
  };

  return (
    <footer id="Footer">
      <div className='footer-box'>
        <div className='box-items-footer'>
          <img src={LogoTotalSegWhite} alt='Logo TotalSeg' />
          <div className='box-items-footer-icons'>
            <a href={linkFacebook} target='_blanck'> <AiFillFacebook /> </a>
            <a href={linkInstagram} target='_blanck'> <AiFillInstagram /> </a>
            <a href={linkLinkedin} target='_blanck'> <AiFillLinkedin /> </a>
            <a href={linkWhatsapp} target='_blanck'> <IoLogoWhatsapp /> </a>
          </div>
        </div>
        <div className='box-items-footer'>
            <h3>Produtos</h3>
            <Link to={'/produtos/Infraestrutura'}>Infraestrutura</Link>
            <Link to={'/produtos/Som-Ambiente'}>Som Ambiente</Link>
            <Link to={'/produtos/Elétrica'}>Elétrica</Link>
            <Link to={'/produtos/Telefonia'}>Telefonia</Link>
            <Link to={'/produtos/Informática'}>Informatica</Link>
        </div>
        <div className='box-items-footer'>
          <div className='box-item-footer-title'>
            <h3>Newsletter</h3>
            <p>Preencha a nossa Newsletter e receba nossas novidades</p>
          </div>
          <form id='form-newsletter' onSubmit={submitNewsletter}>
            <input type='text' value={emailNewsletter} placeholder='Exemplo: contato@contato.com.br' onChange={(e) => setEmailNewsletter(e.target.value)} />
            <button type='submit' id='btn-form-newsletter'>Enviar</button>
          </form>
        </div>
      </div>
      <div className='novaya-box'>
       <a href='https://www.instagram.com/novaya.on/' target='_blanck'><img src={Novayalogo} alt='Logo Novaya' /></a>
      </div>
    </footer>
  );
}
  
export default Footer;