//Assets

//Imports
import './Navbar.style.css';
import logoImg from '../../Assets/logos/logo-complet-bg.webp';
import { BsSearch } from "react-icons/bs";
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import whatsappNumber from '../../Services/WhatsappNumber';

//Components/Sections


//Configs
const linkWhatsapp = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;


function Navbar() {
  const [searchTerm, setSearchTerm] = useState('');
  const [isMenuActive, setIsMenuActive] = useState(false);
  const navigate = useNavigate();

  const submitSearch = (event) => {
    event.preventDefault();
    if (searchTerm) {
      navigate(`/buscar/${searchTerm}`);
      setSearchTerm('')
    } else {
      return
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      submitSearch(event);
    }
  };

  const toggleMenu = () => {
    if (isFlexMenu) {
      setIsMenuActive(!isMenuActive);
      setAria()
    }
  };

  const verifyMenu = () => {
    if (isFlexMenu) {
      toggleMenu()
    }
  }

  const setAria = () => {
    let btnMenu = document.querySelector('.btn-menu');
    let Nav = document.querySelector('nav');
    let isActive = Nav.classList.contains('nav-menu-active');
    
    btnMenu.setAttribute('aria-expanded', !isActive);
    if (isActive) {
        btnMenu.setAttribute('aria-label', 'Abrir Menu');
    } else {
        btnMenu.setAttribute('aria-label', 'Fechar Menu');
    }
  }

  const isFlexMenu = () => {
    /*let btnMenu = document.querySelector('.btn-menu');
    let btnMenuStyles = getComputedStyle(btnMenu);
    let isFlex = btnMenuStyles.display === 'flex';
    return isFlex*/
    let isFlex = document.querySelector('.btn-menu').getComputedStyle().display === 'flex';
    return isFlex
  }

  return (
    <nav id="Navbar" className={isMenuActive ? 'nav-menu-active' : ''} >
      <div className='nav-topside'>
        <Link to={'/'}><img src={logoImg} alt='Logo Totalseg' /></Link>
        <form onSubmit={ submitSearch }>
          <label>
            <div className='box-icon-input-nav'>
              <BsSearch />
            </div>
            <input type='text' placeholder='O que está procurando?' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onKeyDown={handleKeyDown} />
          </label>
        </form>
        <button className='btn-menu' onClick={toggleMenu} aria-label="Abrir Menu" aria-haspopup="true" aria-controls="menu" aria-expanded="false">
              <span className="line line1"></span>
				      <span className="line line2"></span>
				      <span className="line line3"></span>
        </button>
        <a href={ linkWhatsapp } target='_blanck' className='link-orcament'>Solicitar Orçamento</a>
      </div>
      <div className='nav-menu'>
        <ul className='menu-items'>
          <ul className='menu-item'>
            <p>Todas as Categorias</p>
            <ul className='inside-menu'>
              <li><Link to={'/produtos/Infraestrutura'} onClick={verifyMenu}>Infraestrutura</Link></li>
              <li><Link to={'/produtos/Som-Ambiente'} onClick={verifyMenu}>Som Ambiente</Link></li>
              <li><Link to={'/produtos/Elétrica'} onClick={verifyMenu}>Elétrica</Link></li>
              <li><Link to={'/produtos/Telefonia'} onClick={verifyMenu}>Telefonia</Link></li>
              <li><Link to={'/produtos/Informática'} onClick={verifyMenu}>Informática</Link></li>
            </ul>
          </ul>
          <ul className='menu-item'>
            <div className='menu-box-icon'>
              <span className='menu-icon icon-security'></span>
            </div>
            <p>Segurança</p>
            <ul className='inside-menu'>
              <li><Link to={'/produtos/Controle-de-Acesso'} onClick={verifyMenu}>Controle de acesso</Link></li>
              <li><Link to={'/produtos/CFTV'} onClick={verifyMenu}>CFTV</Link></li>
              <li><Link to={'/produtos/Centrais-de-Alarme'} onClick={verifyMenu}>Centrais de alarmes</Link></li>
              <li><Link to={'/produtos/Catracas'} onClick={verifyMenu}>Catracas</Link></li>
              <li><Link to={'/produtos/Cancelas'} onClick={verifyMenu}>Cancelas</Link></li>
              <li><Link to={'/produtos/Comunicação'} onClick={verifyMenu}>Comunicação</Link></li>
              <li><Link to={'/produtos/Cercas-Elétricas'} onClick={verifyMenu}>Cercas Elétricas</Link></li>
            </ul>
          </ul>
          <ul className='menu-item'>
            <div className='menu-box-icon'>
              <span className='menu-icon icon-cabeamento'></span>
            </div>
            <p>Cabeamento</p>
            <ul className='inside-menu'>
              <li><Link to={'/produtos/Redes'} onClick={verifyMenu}>Redes</Link></li>
              <li><Link to={'/produtos/Fibra'} onClick={verifyMenu}>Fibra</Link></li>
            </ul>
          </ul>
          <ul className='menu-item'>
            <div className='menu-box-icon'>
              <span className='menu-icon icon-settings'></span>
            </div>
            <p>Automação</p>
            <ul className='inside-menu'>
              <li><Link to={'/produtos/Casa-Inteligente'} onClick={verifyMenu}>Casa Inteligente</Link></li>
              <li><Link to={'/produtos/Automatizadores-de-Portão'} onClick={verifyMenu}>Automatizadores de portão</Link></li>
            </ul>
          </ul>
        </ul>
      </div>
    </nav>
  );
}
  
export default Navbar;